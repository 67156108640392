import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default/icons'
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/models/dom';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/charmap';

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tinymce"
export default class extends Controller {
  connect() {
    tinymce.init({
      menubar: false,
      plugins: 'hr link image code lists charmap table',  // note the comma at the end of the line!
      // toolbar: 'heading | bold italic strikethrough link | blockquote code bullist numlist | table | alignleft aligncenter alignright alignjustify alignnone removeformat | outdent indent undo redo',
      toolbar: 'headinghgroup | bold italic formatgroup | table | alignleft aligncenter alignright paragraphgroup | insertgroup | undo redo',
      toolbar_groups: {
        formatgroup: {
          icon: 'format',
          tooltip: 'Formatting',
          items: 'underline strikethrough | forecolor backcolor | superscript subscript | removeformat'
        },
        paragraphgroup: {
          icon: 'paragraph',
          tooltip: 'Paragraph format',
          items: 'bullist numlist | blockquote code | alignjustify alignnone | indent outdent'
        },
        headinghgroup: {
          icon: 'permanent-pen',
          tooltip: 'Paragraph format',
          items: 'h2 h3 h4'
        },
        insertgroup: {
          icon: 'plus',
          tooltip: 'Insert',
          // items: 'link image emoticons charmap hr'
          items: 'link emoticons charmap hr'
        }
      },
      table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
      selector: 'textarea[data-controller="tinymce"]',  // change this value according to your HTML
    });
  }

  disconnect () {
    tinymce.remove()
  }
}