// Usage:
// Set class to string input. Default is set selecting date from today. It supports connect other input
// for setting end date. This input must be as string and have same settings as first input. Setting is set
// by data attribute - data-datepicker-conf. It contains json string with supported configuration.
// Supported configuration:
//    other: 'id of other input'
//    minDate: '' - set minimum date which is possible set. More info in flatpickr documentation.
// Classes:
//   .datepicker = for date calendar
//   .timepicker = for time calendar
//   .datetimepicker = for datetime calendar
// Example of using in simple form (Booking model):
//   = f.input :start_date, as: :string, input_html: {class: 'datetimepicker',
//         data: { datepicker_conf: {minDate: '', other: 'booking_end_date'}}}
//   = f.input :end_date, as: :string
import flatpickr from "flatpickr";

function dateIsInEnableRange(instance) {
  var dateobj = instance.parseDate(instance.input.value);
  status = false;

  if (instance.config.minDate !== undefined && flatpickr.compareDates(dateobj, instance.config.minDate) >= 0) {
    status = true;
  }
  if (instance.config.maxDate !== undefined) {
    if (flatpickr.compareDates(dateobj, instance.config.maxDate) <= 0) {
      status = true;
    }
    else {
      status = false;
    }
  }
  else if (instance.config.minDate == undefined) {
    status = true;
  }
  return status;
}

function checkWorkingDays(date) {
  if ($('#user_id')[0].value > 0) {
    return false;
  }
  let opened = JSON.parse($('#opening_hours_field').val())
  return opened[date.getDay()] == undefined ;
}

const toggleDateInputs = function() {
    const default_conf = {
        enableTime: false,
//        minDate: "today",
        disable: [checkWorkingDays],
        defaultHour: 9,
        time_24hr: true,
        dateFormat: "d/m Y H:i",
        onOpen: [
            function(selectedDates, dateStr, instance){
                if (instance.input.value === "") {
                  instance.clear();
                }
                else {
                  if (dateIsInEnableRange(instance)) {
                    instance.setDate(instance.input.value, undefined, undefined, true);
                  }
                }
            },
        ],
    };
    const dateInputs = document.querySelectorAll(".datepicker, .timepicker, .datetimepicker");

    if (dateInputs.length) {
        for (let startDateInput of dateInputs) {
            const conf = Object.assign({}, default_conf);
            conf.allowInput = true;
            if (startDateInput.classList.contains("timepicker")) {
                conf.enableTime = true;
                conf.noCalendar = true;
                conf.dateFormat = "H:i";
            }
            else if (startDateInput.classList.contains("datetimepicker")) {
                conf.enableTime = true;
            }
            if (startDateInput.dataset && startDateInput.dataset.datepickerConf) {
                let newConf = JSON.parse(startDateInput.dataset.datepickerConf);

                if (newConf.minDate || newConf.minDate == "") {
                    conf.minDate = newConf.minDate;
                }
                if (newConf.other) {
                    const endDateInput = document.getElementById(newConf.other);
                    if (endDateInput) {
                        const conf2 = Object.assign({}, conf);
                        conf2.defaultHour = 18;
                        if (startDateInput.value == "") {
                            endDateInput.disabled = true;
                        }
                        else {
                            conf2.minDate = startDateInput.value;
                            flatpickr(endDateInput, conf2);
                        }
                        startDateInput.addEventListener("change", (e) => {
                            conf.defaultDate = e.target.value;
                            if (startDateInput != "") {
                                endDateInput.disabled = false;
                            }
                            conf2.minDate = e.target.value;
                            flatpickr(endDateInput, conf2);
                        })
                    }
                }
            }
            flatpickr(startDateInput, conf);
        }
    }
};

export { toggleDateInputs }
