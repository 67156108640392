
import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { data } from "jquery";
import moment from 'moment';
import { start } from "turbolinks";

// Connects to data-controller="tinymce"
export default class extends Controller {
  connect() {
    const container = this.element
    const calendarEl = document.getElementById('calendar');
    var availabilityUrl = calendarEl.dataset.availabilityUrl;
    var availability = {}

  function checkAvailability(date) {
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (!availability[year + '-' + month]) {
      $.ajaxSetup({async: false});
      $.get(availabilityUrl, {month: month, year: year}, function(data) {
        availability[year + '-' + month] = data;
      })
    }
    var dateStr = `${date.getFullYear()}/${(date.getMonth() + 1)}/${date.getDate()}`;
    let day = availability[year + '-' + month][dateStr];
    if (!day) return false;
    if (jQuery.isEmptyObject({ ...day })) return false;

    return day;
  }

    function setStartDate() {
      let startTime = $('[name="fromtime"]:checked').val();
      let startDate = fromDate.selectedDates[0];
      let startHour = startTime.split(':')[0];
      let startMinute = startTime.split(':')[1];
      startDate.setHours(startHour, startMinute, 0, 0);
      fromDate.setDate(startDate);
    }

    function setEndDate() {
      let endTime = $('[name="totime"]:checked').val();
      let endDate = toDate.selectedDates[0];
      let endHour = endTime.split(':')[0];
      let endMinute = endTime.split(':')[1];
      endDate.setHours(endHour, endMinute, 0, 0);
      toDate.setDate(endDate, undefined, undefined, true);
    }

    function addTimeSelector(time, prefix) {
      let time_selector = $(`
        <div class="shop-time-selector">
          <input class="form-check-input" type="radio" name="${prefix}time" id="${prefix}time${time}" value="${time}">
          <label class="form-check-label" for="${prefix}time${time}">${time}</label>
        </div>
      `)
      time_selector.appendTo(`#${prefix}-time`);
    }

    function setTimeSlots(date, prefix) {
      $(`#${prefix}-time`).empty();

      for (const [key, value] of Object.entries(checkAvailability(date))) {
        if (value) {
          addTimeSelector(key, prefix);
        }
      }

      if (prefix == 'from') {
        $('[name="fromtime"]').first().prop('checked', true);
        setStartDate();
      }
      if (prefix == 'to') {
        $('[name="totime"]').last().prop('checked', true);
        setEndDate();
      }
    }
    const fromDate = flatpickr(container.querySelector('#fromDate'), {
      defaultHour: 9,
      time_24hr: true,
      dateFormat: "d/m Y H:i",
      minDate: "today",
      enableTime: false,
      defaultDate: container.querySelector('#fromDate').value,
      disable: [
        function(date) {
          return !checkAvailability(date)
        }
      ],
      onChange: (selectedDates, _dateStr, _instance) => {
        if (typeof toDate !== 'undefined') {
          let value = toDate.input.value
          toDate.config.minDate = selectedDates[0];
          toDate.setDate(value);
        }
        setTimeSlots(selectedDates[0], 'from');
      },
      onOpen: function(selectedDates, _dateStr, _instance) {
        fromDate.setDate(fromDate.input.value);
      }
    });
    var toDate;
    if (container.querySelector('#toDate')) {
        toDate = flatpickr(container.querySelector('#toDate'), {
          defaultHour: 18,
          time_24hr: true,
          dateFormat: `d/m Y H:i`,
          minDate: "today",
          enableTime: false,
          disable: [
            function(date) {
              return !checkAvailability(date)
            }
          ],
          defaultDate: container.querySelector('#toDate').value,
          onChange: (selectedDates, _dateStr, _instance) => {
            let value = fromDate.input.value
            fromDate.config.maxDate = selectedDates[0];
            fromDate.setDate(value);
            setTimeSlots(selectedDates[0], 'to');
          },
          onOpen: function(selectedDates, _dateStr, _instance) {
            toDate.setDate(toDate.input.value);
          }
        });
    }

    function validateDate(start_date, end_date) {
      if (typeof start_date === 'string') {
        fromDate.setDate(start_date, undefined, undefined, true);
        start_date = fromDate.selectedDates[0]
      }
      if (typeof end_date === 'string') {
        toDate.setDate(end_date, undefined, undefined, true);
        end_date = toDate.selectedDates[0]
      }
      /*
      if (start_date || end_date) {
        let start_time, end_time, start_wd, end_wd, start_status, end_status, start_tc_status, end_tc_status, tc_start_date, tc_end_date;

        if (start_date) {
          tc_start_date = start_date.valueOf();
          start_time = ((start_date.getTime() - start_date.setHours(0,0,0,0)) / 1000);
          start_wd = start_date.getDay();
          start_status = checkTime(start_time, start_wd);
          start_tc_status = checkTime(tc_start_date, start_wd, 1);
        }

        if (end_date) {
          tc_end_date = end_date.valueOf();
          end_time = ((end_date.getTime() - end_date.setHours(0,0,0,0)) / 1000);
          end_wd = end_date.getDay();
          end_status = checkTime(end_time, end_wd);
          end_tc_status = checkTime(tc_end_date, end_wd, 1);
        }

        let submit_btn = document.querySelector('#active-booking-time-form .submit-btn');
        if (start_status && (end_status == undefined || end_status)) {
          container.querySelector('.errors').classList.add("d-none");
          submit_btn.disabled = false;
        } else {
          container.querySelector('.errors').classList.remove("d-none");
          submit_btn.disabled = true;
        }

        if (start_tc_status && (end_tc_status == undefined || end_tc_status)) {
          container.querySelector('.errors-temporary-closure').classList.add("d-none");
          //submit_btn.disabled = false;
        } else {
          container.querySelector('.errors-temporary-closure').classList.remove("d-none");
          submit_btn.disabled = true;
        }
      } else {
        return false;
      }

      function checkTime(time, wd, tc = 0) {
        function stringToTime(time) {
          let t = time.split(':');
          return parseInt(t[0]) * 3600 + parseInt(t[1]) * 60;
        }
        if (tc == 1) {
          let closures_text = container.querySelector('.errors-temporary-closure .closures');
          let closure_date_format = 'DD/MM YYYY HH:mm';
          for (let i = 0; i < temporary_closure.length; i++) {
            // Split to remove timezone
            let open = new Date(temporary_closure[i].start_date.split('.')[0]);
            let close = new Date(temporary_closure[i].end_date.split('.')[0]);

            if (time >= open && time <= close) {
              closures_text.innerHTML = moment(open).format(closure_date_format) + ' - ' + moment(close).format(closure_date_format);
              return false;
            }
          }
          return true;
        }
        else {
          wd = wd == 0 ? 7 : wd;
          if (!open_time[wd]) return false;

          for (let i = 0; i < open_time[wd].length; i++) {
            let open = stringToTime(open_time[wd][i].from);
            let close = stringToTime(open_time[wd][i].to);

            if (time >= open && time <= close) {
              return true;
            }
          }
          return false;
        }
      }
      */
    }

    $(container ).on('change', '[name="fromtime"]', function () {
      setStartDate()
    })

    $(container ).on('change', '[name="totime"]', function () {
      setEndDate()
    })

    $('#active-booking-time-form').on('shown.bs.modal', function () {
      validateDate(fromDate.input.value, typeof toDate !== 'undefined' ? toDate.input.value : undefined);

      setTimeSlots(fromDate.selectedDates[0], 'from');
      if (typeof toDate !== 'undefined') {
        setTimeSlots(toDate.selectedDates[0], 'to');
      }
    });
  }
}
