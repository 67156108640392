import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
//import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { formatDate } from '../packs/helper';


const renderCalendar = function() {
  var calendarEl = document.getElementById('calendar');
  if (calendarEl) {
    var availabilityUrl = calendarEl.dataset.availabilityUrl;
    var availability = {};
  }

  function checkAvailability(date) {
    if (!calendarEl) return 1;

    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (!availability[year + '-' + month]) {
      $.ajaxSetup({async: false});
      $.get(availabilityUrl, {month: month, year: year}, function(data) {
        availability[year + '-' + month] = data;
      })
    }
    var dateStr = `${date.getFullYear()}/${(date.getMonth() + 1)}/${date.getDate()}`;
    let day = availability[year + '-' + month][dateStr];
    if (!day) return 0;

    return Math.max(...Object.values(day));
  }

  function selectDate(info) {
    if (calendarEl.dataset.setting) {
      var setting = JSON.parse(calendarEl.dataset.setting);
      var opt = setting.options.find(o => o.name === 'selectable');
      if (opt.state == 1) {
        var modal_form = $(`#${opt.form_id}`);
        let value = checkAvailability(info.date)
        if (value <= 0) return false;

        if (opt.form_start_id) {
          modal_form.find(`#${opt.form_start_id}`).val(formatDate(`${info.dateStr} 9:00`));
        }
        if (opt.form_end_id) {
          var modal_end_date = modal_form.find(`#${opt.form_end_id}`)
          modal_end_date.val(formatDate(`${info.dateStr} 18:00`))
          modal_end_date.removeAttr('disabled');
        }
        modal_form.modal("show");
      }
      else {
        alert(opt.disable_message);
      }
    }
  }

  if(calendarEl) {
    var itemAvailability = JSON.parse(calendarEl.dataset.availability || '[]');
    var itemTotal = calendarEl.dataset.totalAmount;
    var cal_config = {
      headerToolbar: {
        left: 'dayGridMonth,dayGridWeek,dayGridDay',
        center: 'title'
  //      left: 'dayGridMonth,dayGridWeek,timeGridDay,listWeek',
      },
      eventTextColor: '#fff',
      plugins: [ dayGridPlugin, listPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      timeZone: 'UTC',
      themeSystem: 'bootstrap',
      timeFormat: 'H(:mm)',
      firstDay: 1,
      height:'auto',
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        hour12: false
      },
      dateClick: function(info) {
        if ($('.calendar-page').length) {
          window.open(calendarEl.dataset.newOrder + '?date=' + info.dateStr);
        } else {
          selectDate(info);
        }
      },
      dayCellDidMount: function(info) {
        if (info.date < (new Date()).setHours(0,0,0,0)) return;
        let value = checkAvailability(info.date)
        if (value > 0) {
          info.el.classList.add('in-stock');
          if ($('#calendar.with-stock').length) {
            //info.el.innerHTML += `<span class="text-success availability">${value}</span>`;
          }
        } else {
          info.el.classList.add('out-stock');
        }

        if (calendarEl.dataset.cartFrom > 0 && calendarEl.dataset.cartTo > 0 ) {
          var cartFrom = new Date(calendarEl.dataset.cartFrom * 1000).setHours(12, 0, 0, 0);
          var cartTo = new Date(calendarEl.dataset.cartTo * 1000).setHours(12, 0, 0, 0);
          if (info.date.setHours(12, 0, 0, 0) >= cartFrom && info.date.setHours(12, 0, 0, 0) <= cartTo) {
            info.el.classList.add('cart-date');
          }
        }
        return info.el;
      },
      events: calendarEl.dataset.events
    };
    if (calendarEl && calendarEl.dataset && calendarEl.dataset.events) {
      if (calendarEl.classList.contains('calendar-small')) {
        cal_config.headerToolbar = {
          left: 'prev',
          center: 'title',
          right: 'next',
        };
      }
      if (calendarEl.dataset.setting) {
        var setting = JSON.parse(calendarEl.dataset.setting);

        if (setting && setting.options) {
          for (var i = 0; i < setting.options.length; i++) {
            var opt = setting.options[i];
            switch(opt.name) {
              case "selectable":
                cal_config.plugins.push(interactionPlugin);
                cal_config.selectable = true;
            }
          }
        }
      }
      var calendar = new Calendar(calendarEl, cal_config);

      calendar.render();
    }
  }
};
export { renderCalendar }




