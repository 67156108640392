import 'bootstrap';
import 'controllers';
import '@fortawesome/fontawesome-free/css/all';
import { toggleDateInputs } from '../components/init_flatpickr';
import { renderCalendar } from '../components/init_fullcalendar';
import '../components/init_fullcalendar_timeline';
import { addFields } from '../components/addFields';
import { removeFields } from '../components/removeFields';
import { barCodeReader } from '../components/init_zxing';
import * as ActiveStorage from '@rails/activestorage';
//import bootbox
import 'bootstrap-select';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'stylesheets/application';
import "chartkick/chart.js"
import bwipjs from 'bwip-js';
import "trix"
import "@rails/actiontext"

window.toggleDateInputs = toggleDateInputs;

require('@rails/ujs').start();
//require("turbolinks").start()
require('@rails/activestorage').start();
require('channels');

ActiveStorage.start();

document.addEventListener('DOMContentLoaded', function () {
  renderCalendar();
  toggleDateInputs();

  if (document.querySelector('fieldset')) {
    new addFields();
    new removeFields();
  }

  $('#accessoriesModal').modal('show');
  $.get($('#accessoriesModal').data('accessories-url'), function(result) {
    $('.accessories_container').html(result.html)
  })
});

window.barCodeReader = barCodeReader;

// Open Image Preview modal across application
document.addEventListener('click', function (event) {
  var target = event.target;
  if (target.className == 'open-image-preview') {
    let rightArrow = document.querySelector('.right-gallery-arrow');
    let leftArrow = document.querySelector('.left-gallery-arrow');
    let imageField = document.querySelector('#image-preview img');
    imageField.src = '';
    imageField.src = target.dataset.previewUrl; // Load preview
    let modal = document.querySelector('#image-preview');
    let gallery = parseInt(target.dataset.imageGallery, 10) || 0;
    if (gallery > 0) {
      // Load gallery images
      let all_images = document.getElementsByClassName('open-image-preview');
      let last_image = parseInt(
        all_images[all_images.length - 1].dataset.imageGallery,
        10
      );
      modal.dataset.gallery = 'true';

      let next_image = gallery < last_image ? gallery + 1 : 1;
      let previous_image = gallery == 1 ? last_image : gallery - 1;

      rightArrow.dataset.imageId = next_image;
      leftArrow.dataset.imageId = previous_image;
    } else {
      modal.dataset.gallery = 'false';
    }
  }
});

document.addEventListener('keydown', function (event) {
  // init keydown actions for image preview
  if (event.target.id == 'image-preview') {
    if (event.keyCode == 37) {
      document.querySelector('.left-gallery-arrow').click();
    } else if (event.keyCode == 39) {
      document.querySelector('.right-gallery-arrow').click();
    }
  }
});

document.addEventListener('DOMContentLoaded', function () {
  if (document.getElementById('bar-code-container')) {
    let barCodeFormat = document.getElementById('bar-code-container').dataset.barCodeFormat;
    let config = {
      bcid:        barCodeFormat,
      text:        document.getElementById('bar-code-container').dataset.barCodeValue,
      scale:       2,
      includetext: true,
      textxalign:  'center',
    }
    if (barCodeFormat == 'code128') {
      config.height = 10
    }
    let barCodeCanvas = bwipjs.toCanvas('bar-code-canvas', config);
    document.getElementById('bar-code-image').src = barCodeCanvas.toDataURL('image/png');
  }

  var controls;
  async function initScanner() {
    controls = await barCodeReader
      .decodeFromVideoDevice(
        undefined,
        'barCodeContainer',
        (result, err, controls) => {
          if (result) {
            let object_id = result.text;
            let add_to_cart =
              $('#barCodeModal').data('controller') == 'bookings' &&
              $('#barCodeModal').data('action') == 'show';
            $.post(
              $('#barCodeModal').data('get-url'),
              { number: object_id, add_to_cart: add_to_cart },
              function (result) {
                window.location.replace(result.url);
              }
            ).fail(function (xhr, status, error) {
              $('#barcodeError').text(xhr.responseJSON.error);
            });
          }
        }
      )
      .catch((err) => console.error(err));
  }
  $('#barCodeModal').on('shown.bs.modal', function () {
    initScanner();
  });

  $('#barCodeModal').on('hide.bs.modal', function () {
    if (controls) {
      controls.stop();
    }
  });

  $('.clickable-row').on('click', function(e) {
    if ( !($(e.target).is('a, button')) && !($(e.target).closest('a, button').length) ) {
      var href = $(this).data('href');
      if (href) {
        window.location.href = href;
      }
    }
  });
});

document.addEventListener('DOMContentLoaded', function () {
  $('.menu-icon').on('click', function() {
    $('.shop-navbar').toggleClass('open')
  })
});

document.addEventListener('DOMContentLoaded', function () {
  if ($('.booking-form').length) {
    $('.profile-container select').selectpicker();
    $('.add_fields').on('click', function(){
      setTimeout(function() {
        $('.profile-container select').selectpicker();
      }, 100)
    })
  }
})

document.addEventListener('DOMContentLoaded', function () {
  $('.phone-number').each(function(_i, input) {
    window.intlTelInput(input, {
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.4/build/js/utils.js",
      separateDialCode: true,
      hiddenInput: function(telInputName) {
        return {
          phone: "profile[eshop_contact_phone_country]"
        };
      }
    });
  })
});
// testing jquery is loading

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")